import api from "@/axios.js";
import { set } from "core-js/core/dict";
import defaultOptions from "@/components/vue-number/options";
import NumberFormat from "@/components/vue-number/number-format";
import { search } from "core-js/fn/symbol";
import Vue from "vue";

const state = {
  arPayment: [],
  selectedPayment: {},
  selectedCustomer: {},
  selectedShipTo: [],
  selectedInvoice: [],
  selectedOuBank: {},
  filter: {
    startDate: null,
    endDate: null,
    customer: [],
    shipTo: [],
    paymentAccount: [],
    creditNote: [],
    custDeposit: [],
    reason: [],
    ouBank: [],
  },
  invoiceOptions: [],
};

const mutations = {
  setArPayment(state, value) {
    state.arPayment = value;
  },
  setInvoiceOptions(state, value) {
    state.invoiceOptions = value;
  },
  setSelectedPayment(state, value) {
    state.selectedPayment = value;
  },
  setSelectedCustomer(state, value) {
    state.selectedCustomer = value;
  },
  setselectedShipTo(state, value) {
    state.selectedShipTo = value;
  },
  setselectedInvoice(state, value) {
    state.selectedInvoice = value;
  },
  setselectedOuBank(state, value) {
    state.selectedOuBank = value;
  },
  setFilter(state, value) {
    state.filter = value;
  },
  setFilterCustomer(state, value) {
    state.filter.customer = value;
  },
  setFilterShipTo(state, value) {
    state.filter.shipTo = value;
  },
  setFilterReason(state, value) {
    state.filter.reason = value;
  },
  setFilterPaymentAccount(state, value) {
    value.map((item) => {
      // item.AccountName = atob(item.AccountName);
      // item.AccountNumber = atob(item.AccountNumber);
      item.BankName = item.bank_name;
      item.BankID = item.bank_id;
      item.Issuer = item.bank_name;
      item.AccountName = item.account_name;
      item.AccountNumber = item.account_number;
    });
    state.filter.paymentAccount = value;
  },
  setFilterCreditNote(state, value) {
    state.filter.creditNote = value;
  },
  setFilterCustDeposit(state, value) {
    state.filter.custDeposit = value;
  },
  setFilterOuBank(state, value) {
    state.filter.ouBank = value;
  },
  setPaidWithoutFormat(state, params) {
    state.selectedInvoice[params.index].PaidWithoutFormat = params.value;
  },
  setPaid(state, params) {
    state.selectedInvoice[params.index].paid = params.value;
  },
  setReasonText(state, params) {
    state.selectedInvoice[params.index].ReasonText = params.value;
  },
  clearState(state) {
    state.filter = {
      startDate: null,
      endDate: null,
      customer: [],
      creditNote: [],
      custDeposit: [],
      reason: [],
      paymentAccount: [],
      shipTo: [],
      ouBank: [],
    };
    state.arPayment = [];
    state.selectedPayment = {};
    state.selectedCustomer = {};
    state.selectedInvoice = [];
    state.selectedShipTo = {};
    state.selectedOuBank = {};
  },
};

const actions = {
  async clearState({ commit }) {
    commit("clearState");
  },
  async fetchCreditNote({ commit }, payload) {
    try {
      const response = await api.get("/api/v1/payment/credit-note/list", {
        params: { customer_id: payload },
      });
      commit("setFilterCreditNote", response.data.creditNote);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchCustDeposit({ commit }, payload) {
    try {
      const response = await api.get("/api/v1/payment/cust-deposit/list", {
        params: { customer_id: payload },
      });
      commit("setFilterCustDeposit", response.data.customerDeposit);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFilterCustomer({ commit }, payload) {
    try {
      const response = await api.get("/api/v1/payment/customer", {
        params: payload,
      });

      // const response = await api.get("/api/v1/master/customer/options", {
      //   params: payload,
      // })
      commit("setFilterCustomer", response.data.customer);
      // commit("setFilterCustomer", response.data.customer)
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFilterShipTo({ commit }, payload) {
    try {
      const response = await api.get(
        "/api/v1/master/customer-address/ship-to?customer_id=" + payload.id,
        {
          params: {
            search: payload.search,
          },
        }
      );
      const shiToOptions = [
        { address: "All", ID: 0 },
        ...response.data.records,
      ];
      commit("setFilterShipTo", shiToOptions);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchFilterPaymentAccount({ commit }, payload) {
    try {
      const response = await api.get(
        "/api/v1/customer-accounts?customer_id=" + payload
      );
      // commit("setFilterPaymentAccount", response.data.paymentAccount);
      commit("setFilterPaymentAccount", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchInvoiceOptions({ commit }, payload) {
    try {
      const response = await api.get("/api/v1/invoice/for-options", {
        params: payload,
      });
      commit("setInvoiceOptions", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchInvoiceOptionsWithPost({ commit }, payload) {
    try {
      const response = await api.post(
        "/api/v1/invoice/for-options",
        { codes: payload.body },
        {
          params: payload.params,
        }
      );
      commit("setInvoiceOptions", response.data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchValidateInvoiceWithPost({ commit }, payload) {
    try {
      const response = await api.post(
        "/api/v1/payment/validate/code-invoice",
        { invoice_code: payload.body },
        {
          params: payload.params,
        }
      );
      commit("setselectedInvoice", response.data.invoices);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchValidateInvoice({ commit }, payload) {
    try {
      // commit('setselectedInvoice', []);
      const response = await api.get("/api/v1/payment/validate/code-invoice", {
        params: payload,
      });

      if (
        response.data.invoices.length === 0 &&
        payload.invoice_code.length > 0
      ) {
        Vue.prototype.$vs.notify({
          title: "Error",
          text: "Invoice not found",
          color: "danger",
          position: "top-right",
        });
        return;
      }

      let formatNumber = new NumberFormat(defaultOptions);
      const invoices = response.data.invoices;
      invoices.forEach((item) => {
        item.Remaining = item.Status === 1 ? item.Total : item.Remaining;
        item.PaidWithoutFormat = item.Remaining;
        item.paid = formatNumber.format(item.Remaining);
        item.reason = null;
        item.ReasonText = "";
      });
      // response.data.invoices.map((item) => {
      //   console.log("Remaining", item.Remaining);
      //   item.Remaining = item.Status === 1 ? item.Total : item.Remaining;
      //   item.PaidWithoutFormat = item.Remaining;
      //   item.paid = formatNumber.format(item.Remaining);
      //   item.reason = null;
      //   item.ReasonText = "";
      //   // item.ReasonText = "";
      // });
      invoices.forEach((item) => {
        state.selectedInvoice.forEach((inv) => {
          if (item.Code === inv.Code) {
            item.Remaining = inv.Remaining ? inv.Remaining : item.Remaining;
            item.PaidWithoutFormat = inv.PaidWithoutFormat
              ? inv.PaidWithoutFormat
              : item.PaidWithoutFormat;
            item.paid = inv.paid ? inv.paid : item.paid;
            item.Reason = inv.Reason ? inv.Reason : item.Reason;
            item.ReasonText = inv.ReasonText ? inv.ReasonText : "";
          }
        });
        console.log(item, "item");
      });

      commit("setselectedInvoice", response.data.invoices);
      if (state.selectedInvoice.length > 1) {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  },

  async fetchReason({ commit }, payload) {
    try {
      const response = await api.get("/api/v1/payment/reason-outstanding");
      commit("setFilterReason", response.data.reason_outstanding);
    } catch (error) {
      console.error(error);
    }
  },

  async fetchOuBank({ commit }, payload) {
    try {
      console.log(payload, "payload fetch bank");
      const response = await api.get("/api/v1/cash-bank/form-bank/"+payload);
      commit("setFilterOuBank", response.data.operatingUnitBank);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  getTotalInvoice(state) {
    console.log(state.selectedInvoice);
    return state.selectedInvoice.reduce(
      (total, invoice) =>
        total + (invoice.Status == 1 ? invoice.Total : invoice.Remaining),
      0
    );
  },
  getTotalPaidInvoice(state) {
    console.log(state.selectedInvoice);
    return state.selectedInvoice.reduce(
      (total, invoice) =>
        total + (invoice.paidWithoutFormat ? invoice.paidWithoutFormat : 0),
      0
    );
  },
  getShipToAddressIDs(state) {
    if (
      state.selectedShipTo.length === 1 &&
      state.selectedShipTo[0].address === "All"
    ) {
      return [];
    } else {
      return state.selectedShipTo.map((ship) => ship.ID);
    }
  },
  // (state) {
  //         // return state.selectedInvoice.map((inv) => inv.ID);
  //         // return state.selectedInvoice.map()

  //         // get total from state selectedInvoice

  //     },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
